 
import {Helmet} from "react-helmet";
import './App.css';
import './styles.css';  
import './timeline.css';  
import { TagCloud } from 'react-tagcloud'

function App() {  
  const data = [

    { value: 'NodeJs', count: 38 }, 
    { value: 'React Native', count: 38 }, 
    { value: 'VB.NET', count: 70 }, 
    { value: 'HTML', count: 80 },
    { value: 'Ruby', count: 60 },
    { value: 'PHP', count: 90 },
    { value: 'Python', count: 50 },
    { value: 'MSSql', count: 90 },
    { value: 'Oracle', count: 38 },
    { value: 'MySQL', count: 90 },
    { value: 'PostgreSQL', count: 60 },
    { value: 'AWS', count: 60 },
    { value: 'Microsoft Azure', count: 70 },
    { value: 'Linux', count: 50 },
    { value: 'Microsoft Windows Server', count: 80 },
    { value: 'jQuery', count: 70 },
    { value: 'HiChart', count: 80 },
    { value: 'Laravel', count: 80 },
    { value: 'Ruby on Rails', count: 60 },
    { value: 'GIT', count: 90 },
    { value: 'Visual Studio', count: 80 },
    { value: 'Visual Studio Code', count: 70 },
    { value: 'Eclipse', count: 50 },
    { value: 'Crystal Report', count: 50 },
    { value: 'Jasper Reports', count: 40 },
  ] 

  return (
    <div className="App"> 
      <Helmet>
      <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/animejs/3.2.1/anime.min.js"></script>
      <script src="https://use.fontawesome.com/releases/v5.15.3/js/all.js"></script> 
      <script src="/js/scripts.js" type="text/javascript" />
      <link href='https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic' rel='stylesheet' type='text/css'></link>
      </Helmet>
          
      {/* Navigation*/}
      <nav className="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
        <div className="container" >

          <a href="#sayMyName"  > 
          NAKARIN.PW
          </a>
          <button className="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i className="fas fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#portfolio">Portfolio</a></li>
              <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#about">About</a></li>
              <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#contact">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Masthead*/}
      <header className="masthead text-white text-center" id="sayMyName" style={{ backgroundImage: "url(assets/img/myBg6.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: '100% ', backgroundPosition: 'center', backgroundColor :"black" }} >
        <div className="container d-flex align-items-center flex-column"  >
          
          <div className="divider-custom divider-light">
            <div className="row py-5" style={{height:"200px"}}>
            </div> 
          </div>
          {/* Masthead Heading*/}
          <h1 className="masthead-heading text-uppercase mb-0" style={{textShadow: "2px 2px 4px #000000" }}>Nakarin Maneerat</h1>
          {/* Icon Divider*/}
          <div className="divider-custom divider-light">
            <div className="divider-custom-line" />
            <div className="divider-custom-icon"><i className="fas fa-star" /></div>
            <div className="divider-custom-line" />
          </div>
          {/* Masthead Subheading*/}
          <p className="masthead-subheading font-weight-light mb-0" style={{textShadow: "1px 1px 2px #000000" }}>Bringing imaginations into reality and solving problems and increase one’s productivity with least physical presence of the person in the office.</p>
        </div> 
      </header>
      {/* Portfolio Section*/}
      <section className="page-section portfolio" id="portfolio">
        <div className="container">
          {/* Portfolio Section Heading*/}
          <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Portfolio</h2>
          {/* Icon Divider*/}
          <div className="divider-custom">
            <div className="divider-custom-line" />
            <div className="divider-custom-icon"><i className="fas fa-star" /></div>
            <div className="divider-custom-line" />
          </div>
          {/* Portfolio Grid Items*/}
          <div className="row ">
            <div className="col-lg-12 ml-auto">
            
              <ul class="timeline">
                <li>
                  <div class="direction-l2">
                    <div class="flag-wrapper">
                      <span class="flag">Freshket</span><br/>
                      <span class="time-wrapper"><br/><span class="time">September 2021– Now</span></span>
                    </div>
                    <div class="desc">Tech Team Lead</div>
                    <div class="desc"><small>Assists management with hiring processes and new team member training.</small></div>
                    <div class="desc"><small>Answers team member questions, helps with team member problems, and oversees team member work for quality and guideline compliance.</small></div>
                    <div class="desc"><small>Communicates deadlines and goals to team members. </small></div>
                    <div class="desc"><small>Translates high-level requirements into detailed designs, and technical estimates.</small></div>
                    <div class="desc"><small>Possess solid communication skills and a strong customer focus.</small></div>
                  </div>
                </li>

                <li>
                  <div class="direction-r2">
                    <div class="flag-wrapper">
                      <span class="flag">SCB Protect</span><br/>
                      <span class="time-wrapper"><br/><span class="time">July 2020– September 2021</span></span>
                    </div>
                    <div class="desc">AVP MIS & IT Solution</div>
                    <div class="desc"><small>Develop sales manpower tool for a company to allocate and estimate the needs of the company. The organization is able reallocate its human resources and utilize them efficiently.</small></div>
                    <div class="desc"><small>Develop and manage dashboards and reports to support HR Department and Sales & Marketing Department.</small></div>
                  </div>
                </li>
                
                <li>
                  <div class="direction-l2">
                    <div class="flag-wrapper">
                      <span class="flag">Bangkok Bank</span><br/>
                      <span class="time-wrapper"><span class="time">April 2019– July 2020</span></span>
                    </div>
                    <div class="desc">Software Specialist-Analytics</div>
                    <div class="desc"><small>Development focused on Chatbot. Collaborating with cross-functional teams to define, design, and ship new Chatbots. Provide technical expertise on aspects of the Chatbot infrastructure/ architecture to fulfill requirements.</small></div>
                  </div>
                </li>
                 
                <li>
                  <div class="direction-r2">
                    <div class="flag-wrapper">
                      <span class="flag">Thai Software Engineering</span><br/>
                      <span class="time-wrapper"><span class="time">May 2017– April 2019</span></span>
                    </div>
                    <div class="desc">Senior Software Engineer</div>
                    <div class="desc"><small>Execute full software development life cycle (SDLC). Involve requirement understanding, planning, executing, controlling, monitoring, reviewing and delivering the Lead SCG's Performance Management and KPIs system project.</small></div>
                  </div>
                </li>

                <li>
                  <div class="direction-l2">
                    <div class="flag-wrapper">
                      <span class="flag">AirAsia Berhad (Malaysia)</span><br/>
                      <span class="time-wrapper"><span class="time">June 2015– May 2017</span></span>
                    </div>
                    <div class="desc">Crew controller</div>
                    <div class="desc"><small>Respond for managing the crew schedule on the current day of operation and reacting to changes as they happen by ensuring that all flights have the required crew complement.</small></div>
                  </div>
                </li>
                 
                <li>
                  <div class="direction-r2">
                    <div class="flag-wrapper">
                      <span class="flag">e-synergy (Thailand) </span><br/>
                      <span class="time-wrapper"><span class="time">July 2013 – June 2015</span></span>
                    </div>
                    <div class="desc">Software Engineer and Software Sales Engineer</div>
                    <div class="desc"><small>Coordinate with Fujitsu software system (Japan) to sale the Cloud basic WMS. Provide technical sales support, Identify, evaluate, and recommend proof of concept opportunities. And also work as a Software Engineer to enhance the system to satisfy user requirements.</small></div>
                  </div>
                </li>

                <li>
                  <div class="direction-l2">
                    <div class="flag-wrapper">
                      <span class="flag">AmiVoice Thai </span><br/>
                      <span class="time-wrapper"><span class="time">August 2012 –July 2013</span></span>
                    </div>
                    <div class="desc">Analyst Developer</div>
                    <div class="desc"><small>Installation and do UAT test and Maintain AEON, ACSS collection and AEON CMS Monitoring Operator Tools those are web-based application for monitoring and logging phone conversation between officer and customer by translate voice to text and detect bad or good word.</small></div>
                  </div>
                </li>
                 
                <li>
                  <div class="direction-r2">
                    <div class="flag-wrapper">
                      <span class="flag">Xsidekick Company</span><br/>
                      <span class="time-wrapper"><span class="time">April 2006 –August 2012</span></span>
                    </div>
                    <div class="desc">Analyst Developer</div>
                    <div class="desc"><small>Research, analyze, customize and implement the CRM open-source software of Ayudhya Insurance PCL CRM the Ayudhya Insurance PCL for contacting insurances to retrieve the customer data and remind insurance policy availability.</small></div>
                  </div>
                </li>
                
              </ul>
            </div>  
          </div>
        </div>
      </section>
      {/* About Section*/}
      <section className="page-section bg-primary text-white mb-0" id="about">
        <div className="container">
          {/* About Section Heading*/}
          <h2 className="page-section-heading text-center text-uppercase text-white">About</h2>
          {/* Icon Divider*/}
          <div className="divider-custom divider-light">
            <div className="divider-custom-line" />
            <div className="divider-custom-icon"><i className="fas fa-star" /></div>
            <div className="divider-custom-line" />
          </div>
          {/* About Section Content*/}
          <div className="row justify-content-right">
              <div className="col-lg-6 ml-auto">
                <h4 className="text-uppercase mb-4">EDUCATION</h4>
                <div className="lead caption">
                  <span class="alignleft">Chulalongkorn University</span>
                  <span class="alignright">Thailand</span>
                </div>   
                <div class="clear"></div>
                <div className="small alignleft ">
                  May 2011
                </div>   
                <div class="clear"></div>
                <div className="alignleft ">
                  M.S. (Computer Science)
                </div>  
                <div class="clear  py-3"></div> 
                <div className="lead caption">
                  <span class="alignleft">Thammasat University</span>
                  <span class="alignright">Thailand</span>
                </div>   
                <div class="clear"></div>
                <div className="small alignleft ">
                March 2006
                </div>   
                <div class="clear"></div>
                <div className="alignleft ">
                B.S. (Computer Science)
                </div>  
                <div class="clear py-3"></div> 
                <h4 className="text-uppercase mb-4">CERTIFICATION</h4>
                <div className="lead caption alignleft">
                The Chinese Proficiency Test Level 4 (HSK4)
                </div>   
                <div class="clear"></div>
                <div className="small alignleft ">
                2020
                </div>    
                <div class="clear py-3"></div>  
                
                <div className="lead caption alignleft">
                Test of English for Int. Communication (TOEIC)
                </div>   
                <div class="clear"></div>
                <div className="small alignleft ">
                2018
                </div>    
                <div class="clear py-3"></div>  
                <div className="lead caption alignleft">
                Sun Certified Java Programmer (SCJP)
                </div>   
                <div class="clear"></div>
                <div className="small alignleft ">
                2006
                </div>    
                <div class="clear py-3"></div>  
                

            </div>

            <div className="col-lg-6">
                <h4 className="text-uppercase mb-4">SKILL</h4>

                <div >
                <TagCloud
                  minSize={12}
                  maxSize={35}
                  tags={data}
                  disableRandomColor="true"
                  color="rgb(16, 137, 26)"
                />
                </div>
                
                <div class="clear py-3"></div>  
                

            </div>
            
          </div>
        </div>
      </section> 
      {/* Footer*/}
      <footer className="footer text-center" id="contact">
        <div className="container">
          <div className="row">
            {/* Footer Location*/}
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h4 className="text-uppercase mb-4">Address</h4>
              <p className="lead mb-0">
                Condo Lasalle park <br />
                Lasalle 10, Sukhumvit 105, Bangna
                Bangkok, Thailand 10260
              </p>
            </div>
            {/* Footer Social Icons*/}
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h4 className="text-uppercase mb-4">Around the Web</h4>
              <a className="btn btn-outline-light btn-social mx-1" href="https://www.facebook.com/nakarin"><i className="fab fa-fw fa-facebook-f" /></a> 
              <a className="btn btn-outline-light btn-social mx-1" href="https://www.linkedin.com/in/nakarin-m-6414173b/"><i className="fab fa-fw fa-linkedin-in" /></a> 
              <a className="btn btn-outline-light btn-social mx-1" href="https://github.com/nineoat"><i className="fab fa-fw fa-github" /></a> 

            </div>
            {/* Footer About Text*/}
            <div className="col-lg-4">
              <h4 className="text-uppercase mb-4">Contact Me</h4>
              <p className="lead"><i className="fas fa-envelope" /> Nakarin@gmail.com</p>
              <p className="lead"><i className="fas fa-phone-square-alt" /> (+66)89-928-1952</p> 
            </div>
          </div>
        </div>
      </footer>
      {/* Copyright Section*/}
      <div className="copyright py-4 text-center text-white">
        <div className="container">
          <small>
            Copyright © Nakarin Maneerat{ " "}
            {new Date().getFullYear()} 
          </small>
        </div>
      </div>
      {/* Scroll to Top Button (Only visible on small and extra-small screen sizes)*/}
      <div className="scroll-to-top d-lg-none position-fixed">
        <a className="js-scroll-trigger d-block text-center text-white rounded" href="#page-top"><i className="fa fa-chevron-up" /></a>
      </div>
    </div>
  );
}

export default App;
